<template>
<div>
  <div class="page-title pt-0 pb-3">
    <a href="javascript:;" @click="$router.go(-1)" class="text-dark">
      <i class="icon-arrow-left52 mr-1"></i>
      <span class="font-weight-semibold">Kembali</span>
    </a>
  </div>
  <div class="row g-2">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <div class="d-flex align-items-center justify-content-between">
            <h6 class="card-title font-weight-semibold">Edukasi Terintegrasi</h6>
            <div>

              <!-- Dibuat dropdown untuk akses yg lebih mudah, ubah ke button biasa di dev -->
              <div class="dropdown">
                <button @click="$parent.getList()" class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover.top><i class="icon-spinner11"></i></button>
                <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret v-if="$parent.moduleRole('edukasi_harian') || $parent.moduleRole('edukasi_terintegrasi') || $parent.moduleRole('edukasi_terintegrasi_nyeri')">
                  <template #button-content>
                    <button type="button" class="btn btn-labeled btn-labeled-left btn-info dropdown-toggle">
                      <b><i class="icon-plus2"></i></b>
                      <span>Tambah Edukasi</span>
                    </button>
                  </template>

                  <b-dropdown-item @click="toForm('EDUKASI_HARIAN_PRWT')" v-if="$parent.moduleRole('edukasi_harian')">
                    Form Edukasi Harian
                  </b-dropdown-item>
                  <b-dropdown-item @click="toForm('EDUKASI_INTEGRASI_PRWT')" v-if="$parent.moduleRole('edukasi_terintegrasi')">
                    Form Edukasi Perawat dan Bidan
                  </b-dropdown-item>
                  <b-dropdown-item @click="toForm('EDUKASI_INTEGRASI_NYERI')" v-if="$parent.moduleRole('edukasi_terintegrasi_nyeri')">
                    Form Edukasi Nyeri
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </div>
        </div>
        <b-tabs nav-class="nav-tabs-bottom nav-justified mt-2 mb-0">
          <b-tab title="Edukasi Terintegrasi">
            <div class="row">
              <div class="col-md-12">
                <div class="table-responsive mb-0">
                  <table class="table table-bordered table-sm">
                    <thead>
                      <tr>
                        <th colspan="2">Uraian Penjelasan Edukasi</th>
                        <th>Penerima Penjelasan / Sasaran</th>
                        <th>Tanda Tangan</th>
                      </tr>
                    </thead>
                    <tbody v-for="(v, k) in $parent.edukasiTerintegrasi" :key="`et-${k}`">
                      <tr class="table-info">
                        <td colspan="4">                          
                          <div class="d-flex align-items-center">
                            <div class="d-flex align-items-center">
                              <div class="font-weight-semibold mr-2">Form Edukasi <span class="text-capitalize">{{ (v.type||'').toLowerCase() }}</span> - {{ v.education_date | moment("LLL") }} </div>
                              <small class="text-secondary">Pelapor: <span class="text-capitalize">{{ v.type.toLowerCase() }}</span> - {{ v.nama_petugas }}</small> <b-badge variant="danger" class="ml-2" v-if="v.show_reeducation_label">Re-Edukasi</b-badge>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="align-top" width="35%">
                          <div class="mb-2">
                            <div class="font-weight-semibold">Materi Edukasi</div>
                            <ul class="pl-3 mb-0">
                              <li v-for="(materi, kMateri) in v.materi" :key="`mat-${kMateri}`">{{ materi.label }}</li>
                            </ul>
                          </div>
                          <div class="mb-2">
                            <div class="font-weight-semibold">Tingkat Pemahaman Awal</div>
                            <span>{{ objMrPemahamanAwal[v.pemahaman_awal] }}</span>
                          </div>
                          <div class="mb-2" v-if="v.question">
                            <div class="font-weight-semibold">Pertanyaan</div>
                            <span>{{ v.question }}</span>
                          </div>
                        </td>
                        <td class="align-top" width="35%">
                          <div class="mb-2">
                            <div class="font-weight-semibold">Metode Edukasi</div>
                            <span>{{ v.metode.map(dtMetode => dtMetode.label).join(", ") }}</span>
                          </div>
                          <div class="mb-2">
                            <div class="font-weight-semibold">Sarana Edukasi</div>
                            <span>{{ v.sarana.map(dtMetode => dtMetode.label).join(", ") }}</span>
                          </div>
                          <div class="mb-2">
                            <div class="font-weight-semibold">Evaluasi</div>
                            <span>{{ objMrEvaluasi[v.evaluasi] }}</span>
                          </div>
                          <!-- Jika ada Re-edukasi -->
                          <div class="mb-2" v-if="v.reschedule_date">
                            <div class="font-weight-semibold">Tanggal Re-edukasi</div>
                            <span>{{ v.reschedule_date | moment("DD MMMM YYYY") }}</span>
                          </div>
                        </td>
                        <td class="align-top">
                          <span>{{ v.relation_patient }} - {{ v.receiver_name }}</span>
                        </td>
                        <td class="align-top text-center">
                          <a href="javascript:;" @click="viewTTD(v,'ttd_edukator')" v-b-tooltip.hover class="btn btn-icon rounded-round btn-sm alpha-info border-info mb-1 d-block" title="Lihat TTD Edukator"><i class="icon-eye"></i></a>
                          
                          <a href="javascript:;" @click="viewTTD(v,'ttd_sasaran')" v-b-tooltip.hover class="btn btn-icon rounded-round btn-sm alpha-success border-success mb-1 d-block" title="Lihat TTD Sasaran"><i class="icon-eye"></i></a>
                        </td>
                      </tr>
                    </tbody>

                    <tbody v-if="!($parent.edukasiTerintegrasi||[]).length">
                      <tr>
                        <td colspan="99">
                          <h5 class="text-center">
                            Belum ada data edukasi terintegrasi
                          </h5>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </b-tab>

          <b-tab title="Edukasi Harian">
            <div class="row">
              <div class="col-md-12">
                <div class="table-responsive mb-0">
                  <table class="table table-bordered table-sm">
                    <thead>
                      <tr>
                        <th colspan="2">Uraian Penjelasan Edukasi</th>
                        <th>Penerima Penjelasan / Sasaran</th>
                        <th>Tanda Tangan</th>
                      </tr>
                    </thead>
                    <tbody v-for="(v, k) in $parent.edukasiHarian" :key="`eh-${k}`">
                      <tr class="table-info">
                        <td colspan="4">
                          <div class="d-flex align-items-center">
                            <div class="d-flex align-items-center">
                              <div class="font-weight-semibold mr-2">{{ v.education_date | moment("LLL") }}</div>
                              <small class="text-secondary">
                                Pelapor: <span class="text-capitalize">{{ v.type.toLowerCase() }}</span> - {{ v.nama_petugas }}</small>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="align-top" colspan="2">
                          <p v-html="v.description"></p>
                        </td>
                        <td class="align-top">
                          <span>{{ v.relation_patient }} - {{ v.receiver_name }}</span>
                        </td>
                        <td class="align-top text-center">
                          <a href="javascript:;" @click="viewTTD(v,'ttd_edukator')" v-b-tooltip.hover class="btn btn-icon rounded-round btn-sm alpha-info border-info mb-1 d-block" title="Lihat TTD Edukator"><i class="icon-eye"></i></a>
                          
                          <a href="javascript:;" @click="viewTTD(v,'ttd_receiver')" v-b-tooltip.hover class="btn btn-icon rounded-round btn-sm alpha-success border-success mb-1 d-block" title="Lihat TTD Sasaran"><i class="icon-eye"></i></a>
                        </td>
                      </tr>
                    </tbody>

                    <tbody v-if="!($parent.edukasiHarian||[]).length">
                      <tr>
                        <td colspan="99">
                          <h5 class="text-center">
                            Belum ada data edukasi harian
                          </h5>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>

  <b-modal v-model="openTTD" :title="'TTD Digital'" size="sm" ok-only ok-title="Tutup">
    <div class="row">
      <div class="col-md-12 text-center">
        <img :src="ttdDigital" alt="">
      </div>
    </div>
  </b-modal>
</div>
</template>

<script>
const _ = global._
export default {
  props: {},
  components: {},
  data() {
    return {
      dataModal: {},
      openTTD: false,
      ttdDigital: ''
    }
  },
  methods: {
    viewTTD(v,field){
      this.ttdDigital = v[field]
      this.openTTD = true
    },
    toForm(viewType){
      this.$emit('changeViewType', viewType)
      this.$router.push({ name: this.$parent.modulePage, query: { viewType: viewType }, params: { pageSlug: this.$route.params.pageSlug } }).catch(() => {})
    },
    openDetail(data) {
      this.$set(this, 'dataModal', {
        ...data.data,
        ppa: data.ppa
      })
      this.$bvModal.show(`${data.ppa}DetailData`)
    }
  },
  computed: {
    listDischargePlanning() {
      return this.$parent.listDischargePlanning
    },
    pendidikanKesehatanGroups() {
      return _.chunk(this.$parent.pendidikan_kesehatan, 2)
    },

    objMrPemahamanAwal(){
      return this.$parent.mrPemahamanAwal.reduce((acc, obj) => {
        acc[obj.value] = obj.label
        return acc
      }, {})
    },

    objMrEvaluasi(){
      return this.$parent.mrEvaluasi.reduce((acc, obj) => {
        acc[obj.value] = obj.label
        return acc
      }, {})
    }
  }
}
</script>
