<template>
  <span>
    <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true" :reg-id="$route.params.pageSlug" /> 

    <div class="content">
      <view-data v-if="viewType == 'VIEW'||!viewType" @changeViewType="changeViewType(...arguments)" />
      <form-data v-else-if="viewType == 'EDUKASI_INTEGRASI_PRWT' || viewType == 'EDUKASI_HARIAN_PRWT'" />
      <form-data-nyeri v-else-if="viewType == 'EDUKASI_INTEGRASI_NYERI'" />
    </div>
  </span>
</template>

<script>
import PatientInfo from '@/components/Ranap/PatientInfo.vue'
import ViewData from './ViewData.vue'
import FormData from './FormData.vue'
import FormDataNyeri from './FormDataNyeri.vue'
import GlobalVue from '@/libs/Global.vue'

import Gen from '@/libs/Gen.js'

const _ = global._

export default {
  extends: GlobalVue,
  components: {
    PatientInfo, ViewData, FormData, FormDataNyeri
  },
  data(){
    return {
      patientData: {},
      loading: {
        patientInfo: false,
        coreData: false
      },

      hasAddPerawat: false,
      listDischargePlanning: [],

      mrPendidikanKesehatan: [],
      mrHasilPemeriksaan: [],
      mrValidation: {},

      edukasiTerintegrasi: [],
      edukasiHarian: [],

      row: {},
      mrPemahamanAwal: [],
      mrSasaran: [],
      mrMetode: [],
      mrMateri: [],
      mrSarana: [],
      mrEvaluasi: [],
    }
  },

  mounted(){    
    if(this.$route.query.viewType){
      this.changeViewType(this.$route.query.viewType)
    }

    this.getPatientInfo()
    if(this.viewType == 'VIEW'){
      this.getList()
    } else if(this.viewType == 'EDUKASI_INTEGRASI_PRWT'){
      this.getFormEdukasiIntegrasi()
    }else {
      this.getFormEdukasiHarian()
    }
  },

  methods: {
    getPatientInfo() {
      this.$set(this.loading, 'patientInfo', true)
      Gen.apiRest(
        "/do/" + 'RanapPasienPerawatan', {
          data: {
            type: "get-data-pasien",
            id_registrasi: this.$route.params.pageSlug,
          }
        },
        "POST"
      ).then(resp => {
        this.$set(this, 'patientData', resp.data.data)

        this.$set(this.loading, 'patientInfo', false)
      })
    },

    getList(){
      this.$set(this.loading, 'coreData', true)
      Gen.apiRest(
        "/get/RanapEdukasiTerintegrasi", {
          params: {
            type: "get-detail",
            id_registrasi: this.$route.params.pageSlug,
            cara_masuk: this.patientData.aranres_cara_masuk,
            poliId: this.patientData.aranres_ar_id,
            ugdId: this.patientData.aranres_aur_id,
          }
        },
      ).then(res => {
        _.forEach(res.data, (v, k) => {
          this.$set(this, k, v)
        })

        this.$set(this.loading, 'coreData', false)
      })

      Gen.apiRest(
        "/get/" + this.modulePage, {
          params: {
            type: "get-detail",
            id_registrasi: this.$route.params.pageSlug,
            cara_masuk: this.patientData.aranres_cara_masuk,
            poliId: this.patientData.aranres_ar_id,
            ugdId: this.patientData.aranres_aur_id,
          }
        },
      ).then(res => {
        _.forEach(res.data, (v, k) => {
          this.$set(this, k, v)
        })
      })
    },

    getFormEdukasiHarian(){
      this.$set(this.loading, 'coreData', true)
      Gen.apiRest(
        "/get/" + this.modulePage, {
          params: {
            type: "get-form-edukasi-harian",
            id_registrasi: this.$route.params.pageSlug
          }
        },
      ).then(res => {
        _.forEach(res.data, (v, k) => {
          this.$set(this, k, v)
        })

        this.$set(this.loading, 'coreData', false)
      })
    },
    
    getFormEdukasiIntegrasi(){
      this.$set(this.loading, 'coreData', true)
      Gen.apiRest(
        "/get/" + this.modulePage, {
          params: {
            type: "get-form",
            id_registrasi: this.$route.params.pageSlug
          }
        },
      ).then(res => {
        _.forEach(res.data, (v, k) => {
          this.$set(this, k, v)
        })

        this.$set(this.loading, 'coreData', false)
      })
    },

    getFormEdukasiNyeri(){
      this.$set(this.loading, 'coreData', true)
      Gen.apiRest(
        "/get/" + this.modulePage, {
          params: {
            type: "get-form-edukasi-nyeri",
            id_registrasi: this.$route.params.pageSlug
          }
        },
      ).then(res => {
        _.forEach(res.data, (v, k) => {
          this.$set(this, k, v)
        })

        this.$set(this.loading, 'coreData', false)
      })
    },

    changeViewType(view){
      this.$router.push({ name: this.modulePage, params: { ...this.$route.params }, query: { viewType: view } })
    },

    doSubmit(data){
      this.$swal.fire({
        title: 'Loading...',
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading()
        }
      })

      Gen.apiRest(
        "/do/" + this.modulePage, {
          data: {
            ...data,
          }
        },
        "POST"
      ).then(resp => {
        this.$swal.close()
        this.$swal({
          title: resp.data.message,
          icon: 'success',
          confirmButtonText: 'Ok'
        }).then(result => {
          if (result.value) {
            this.$router.push({
              name: this.modulePage,
              params: { ...this.$route.params }
            })
          }
        })
      }).catch(() => {
        this.$swal.close()

        return this.$swal({
          icon: 'error',
          text: 'Terjadi Kesalahan Yang Tidak Diketahui'
        })
      })
    }
  },  

  computed: {
    viewType(){
      return this.$route.query.viewType || "VIEW"
    }
  },
  watch: {
    $route(v) {
      this.getPatientInfo()
      if(this.viewType == 'VIEW' || !v.query.viewType){
        this.getList()
      } else if(this.viewType == 'EDUKASI_INTEGRASI_PRWT'){
        this.getFormEdukasiIntegrasi()
      } else if(this.viewType == 'EDUKASI_INTEGRASI_NYERI') {
        this.getFormEdukasiNyeri()
      } else {
        this.getFormEdukasiHarian()
      }

      if(!v.query.viewType || v.query.viewType == 'VIEW')
        this.changeViewType('VIEW')
    },
  },
}
</script>